*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  color: $primary-color;
  font-family: $primary-font;
  background: #ffffff;
  height: 100%;
}

.center {
  text-align: center
}
